@use "~bootstrap/scss/bootstrap";

$clearcycle-theme-color: #00ed97;

.top-nav {
  .nav-image {
    max-width: 140px;
    margin: 12px 10px 5px 5px;
  }

  .menu a:not(:first-child) {
    color: #000;
    text-decoration: none;
  }
}

.nav-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;

  img {
    max-height: 40px;
    margin: 10px;
  }

  p {
    margin: 0;
  }

  button {
    max-height: 30px;
  }

  .inner {
    p {
      font-size: small;
      text-transform: uppercase;
      margin: 0.5em;
    }
  }
}

.damage-severity-entry {
  border: 1px solid rgb(234, 234, 234);
  border-radius: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.refurb-damage-severity-col {
  font-weight: "bolder";
  display: "flex";
  justify-content: "space-around";
  align-items: "center";
  flex-direction: "column";

  .refurb-severity-level {
    text-align: center;
  }
}

.refurb-plus-minus-row {
  .col {
    display: flex;
    padding: 0.2em;
    justify-content: center;

    button {
      font-size: 1.5em;
      width: 50px;
    }
  }
}

.refurb-damage-recording-number-input {
  max-width: 2em;
  font-size: 1.5em;
  margin-left: 0.5em;
}

.refurbPiiForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 990px) {
  .refurb-item-sidebar {
    position: fixed;
    right: 0;
  }
}

.catalog-item-image-thumb .card-img {
  cursor: pointer;
}
.catalog-item-row:hover {
  td {
    background-color: #38860014 !important;
  }
}

.editable-table {
  .tr {
    display: flex;
  }

  tr,
  .tr {
    width: fit-content;
    height: 30px;
  }

  th,
  .th {
    padding: 2px 4px;
    position: relative;
    font-weight: bold;
    text-align: center;
    height: 30px;
  }

  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }

  .resizer.isResizing {
    background: blue;
    opacity: 1;
  }

  @media (hover: hover) {
    .resizer {
      opacity: 0;
    }

    *:hover > .resizer {
      opacity: 1;
    }
  }
}

.editable-table-body {
  td {
    padding: 0;
  }
  input,
  textarea {
    padding: 1px 3px;
    margin: 1px;
    border: 0px solid transparent;
  }
}

.capitalize-first {
  text-transform: lowercase;
}
.capitalize-first::first-letter {
  text-transform: uppercase;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.catalogue-field-warning {
  padding-left: 10px;
  font-size: 1.2em;
}

.catalog-file-upload-modal {
  max-width: none !important;
  min-height: 400px;
  min-width: 80vw;
  width: 1200px;
}

.csv-preview-table {
  td {
    max-height: 100px;
  }
}

.csv-upload-header-name {
  margin-bottom: 0.1em;
}

.csv-description-preview {
  font-size: small;
  min-width: 300px;
}

.choice-table {
  max-width: 600px;
  text-align: right;

  button {
    min-width: 150px;
    width: 100%;
  }

  td.choice-description {
    text-align: left;
    align-content: center;
  }
}

.image-row {
  display: flex;
  flex-direction: row;
  gap: 10px; /* Adjust the gap between images as needed */
  overflow-x: auto; /* Enable horizontal scrolling */
  max-width: calc(100% - var(--bs-border-radius-lg));
  padding-left: var(--bs-border-radius-lg);
}

.image-container {
  flex: 0 0 auto;
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Adjust the size as needed */
  overflow: hidden;
  cursor: pointer;
}

.square-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(
    --bs-border-radius
  ); /* Adjust the border-radius to match your ImageCarousel */
}

.image-upload-modal {
  min-width: 75%;
}

.content-container {
  display: flex;
  flex-direction: column;
}

.wide-box {
  flex-direction: row;
  justify-content: space-between;
}

.main-content {
  flex: 1;
}

.additional-info {
  flex: 1;
  margin-left: 5px;
  margin-top: -10px;
}

.additional-info div {
  line-height: 0.8;
}

.modal-90w {
  max-width: none !important;
  width: 90%;
}
.modal-90h {
  max-height: none !important;
  height: 90%;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.confirm-delete {
  animation: pulse 2s infinite;
}

.active.carousel-item {
  display: flex;
  justify-content: center;
}

#printarea {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: normal;
  height: auto;
  width: 100%;
  text-align: center;
  margin: 1em;

  .listingtitle-col {
    max-width: min(20vw, 500px);
  }

  table tbody td {
    border-top: 2px dashed black;
  }
  table thead {
    font-weight: bold;
  }
  td {
    font-size: 1.25em;
  }
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 5px solid grey;
}
#palletnumber {
  font-size: 2.5em;
}
#pallettext {
  color: red;
  font-size: 2.5em;
}

#logo {
  width: 50px;
  margin: 10px;
}

.qrcode-entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.nav-header {
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 0.5em;
  padding-left: 1em;
}
